import * as React from 'react';
import {Link} from 'react-router-dom';

import { connect } from 'react-redux';
import { ApplicationState } from '@app/store/index';
import * as PagesState from '@app/store/ItemList';
import {ExtendableItemsPage} from '@app/components/Pages/ItemsPage';

import {dateFormat, getUserNameWithEmail, printPage} from "@app/components/Utils/Utils";
import * as LoginState from "@app/store/Login";
import Table, {ColumnProps} from "antd/lib/table";

import {UserRole} from "@app/objects/User";
import {Action, ActionObject, ActionType} from "@app/objects/Action";


import ReportsActivityFilters, {ReportsActivityFiltersValues} from "./ReportsActiviryFilters";
import { access } from 'fs';

interface ReduxProps {
	user: LoginState.LoginState
}

interface InjuryUser {
	id: number,
	email: string,
	firstName: string,
	lastName: string
}

interface InjuryChangedData {
	vccCase: string,
	employeeId: number,
	previousUser: InjuryUser,
	newUser: InjuryUser
}

function getActionText(action: Action): JSX.Element | undefined | string {
	let data;

	switch (action.objectType) {
		case ActionObject.InjuryUserChanged:
			data = JSON.parse(action.json) as InjuryChangedData;
			switch (action.actionType) {
				case ActionType.Insert:
				case ActionType.Update:
					return <React.Fragment>User {getUserNameWithEmail(data.newUser)} was assigned to VCC Case <Link to={`/employe-editor/${data.employeeId}?case=${data.vccCase}`}>{data.vccCase}</Link></React.Fragment>;
				case ActionType.Delete:
					return <React.Fragment>User {getUserNameWithEmail(data.previousUser)} was unassigned from VCC Case <Link to={`/employe-editor/${data.employeeId}?case=${data.vccCase}`}>{data.vccCase}</Link></React.Fragment>;
			}
			break;

		case ActionObject.Restriction:
			data = JSON.parse(action.json);
			switch (action.actionType) {
				case ActionType.Insert:
					return <React.Fragment>New restriction in VCC Case <Link to={`/employe-editor/${data.employeeId}?case=${data.vccCase}`}>{data.vccCase}</Link>: ${data.text}</React.Fragment>;
				case ActionType.Delete:
					return <React.Fragment>Restriction: ${data.text} has been deleted from VCC Case <Link to={`/employe-editor/${data.employeeId}?case=${data.vccCase}`}>{data.vccCase}</Link></React.Fragment>;
			}
			break;

		case ActionObject.Injury:
			data = JSON.parse(action.json);

			if (data && data.oldItem && data.newItem) {
				const a = ['stateJurisdiction', 'placementFee1', 'placementFee2', 'placementFee3', 'injuryStatus'];

				let s = '';

				for (var j = 0; j < a.length; j++) {
					if (data.oldItem[a[j]] != data.newItem[a[j]]) {
						s += `${a[j]} from ${data.oldItem[a[j]]} to ${data.newItem[a[j]]} `;
					}
				}

				return <React.Fragment>Changes in VCC Case <Link to={`/employe-editor/${data.employeeId}?case=${data.vccCase}`}>{data.vccCase}</Link>: {s}</React.Fragment>;
			}
			break;
		case ActionObject.Employee:
			data = JSON.parse(action.json);

			if (data && data.oldItem && data.newItem) {
				const a = ['address'];

				let s = '';

				for (var j = 0; j < a.length; j++) {
					if (data.oldItem[a[j]] != data.newItem[a[j]]) {
						s += `${a[j]} from ${data.oldItem[a[j]]} to ${data.newItem[a[j]]} `;
					}
				}

				return <React.Fragment>Changes in <Link to={`/employe-editor/${data.employeeId}`}>employee</Link>: {s}</React.Fragment>;
			} else {
				return <React.Fragment>{ActionType[action.actionType]} {ActionObject[action.objectType]} <Link to={`/employe-editor/${action.objectId}`}>{action.objectName}</Link></React.Fragment>;
			}

			break;
		case ActionObject.StatusReport:
			data = JSON.parse(action.json);

			return <React.Fragment>New status report in VCC Case <Link to={`/employe-editor/${data.employeeId}?case=${data.vccCase}`}>{data.vccCase}</Link></React.Fragment>;

		case ActionObject.InjuryFile:
			data = JSON.parse(action.json);

			return <React.Fragment>New file in VCC Case <Link to={`/employe-editor/${data.employeeId}?case=${data.vccCase}`}>{data.vccCase}</Link></React.Fragment>;

		case ActionObject.Login:
			return <React.Fragment>User {action.user.firstName + ' ' + action.user.lastName} logged in</React.Fragment>;

		default:
			return `${ActionType[action.actionType]} ${ActionObject[action.objectType]} - ${action.objectName}`
	}
}

class ReportsActivity extends ExtendableItemsPage<Action, ReduxProps> {
	constructor(props: any) {
		super(props);

		this.columns = [
			{
				title: 'User',
				dataIndex: 'user',
				render: (text: string, record: Action) => this.renderMobileCell('User', `${record.user.firstName || ''} ${record.user.lastName || ''} (${record.user.email})`)
				
			},
			{
				title: 'Role',
				dataIndex: 'role',
				render: (text: string, record: Action) => this.renderMobileCell('Role', UserRole[record.user.role])
				
			},
			{
				title: 'Action',
				dataIndex: 'action',
				render: (text: string, record: Action) => {
					return this.renderMobileCell('Action', getActionText(record) || '')
				}
			},
			{
				title: 'Date',
				dataIndex: 'time',
				key: 'time',
				render: (text: string, record: Action) => this.renderMobileCell('#', dateFormat(record.time, true))
			},
		];
		
		this.type = 'lastAction';
		this.store = 'lastActions';
		this.path = 'actionList';
		this.listPath = 'change-log';
		this.caption = 'Audit Log';
		this.tableClassName = 'table-mobile';
		this.editorCaption= '';
		this.additionalParams = {};

		this.onApplyFilters = this.onApplyFilters.bind(this);
	}

	onApplyFilters(values: ReportsActivityFiltersValues) {
		this.additionalParams = {
			...this.additionalParams,
			...{
				user: values.user ? values.user.id : 0,
				role: values.role && values.role > 0 ? +values.role : null,
				from: values.date[0] ? values.date[0] : null,
				to: values.date[1] ? values.date[1] : null,
			}
		} as any;

		this.props.reqPages(this.store, this.path, this.type, { page: 1, ...this.additionalParams });
	}
	
	public render() {
		const {items, pagination, isLoading} = this.props;
		
		return <div className="status-reports-list-page">
			<div className="site-headline site-headline_with-button clearfix">
				<h1 className="pull-left">{this.caption}</h1>
			</div>
			<div className="list-filters clearfix">
				<div className="pull-right ml10">
					<button className="btn btn-default" type="button" title="Print" onClick={printPage}><i className="fa fa-print"/></button>
				</div>
				<ReportsActivityFilters onApply={this.onApplyFilters}/>
			</div>	
			<Table
				columns={this.columns}
				dataSource={items}
				pagination={pagination}
				loading={isLoading}
				onChange={this.handleTableChange}
				childrenColumnName="child"
				rowKey="id"
				className={this.tableClassName}
			/>
		</div>
	}
}

export default connect(
	(state: ApplicationState) => ({
		...state.lastActions,
		user: state.login
	}), // Selects which state properties are merged into the component's props
	PagesState.getActionCreators<Action>()                 // Selects which action creators are merged into the component's props
)(ReportsActivity as any) as typeof ReportsActivity;