import {User} from "@app/objects/User";

export enum ActionType
{
	Insert,
	Update,
	Delete
}

export enum ActionObject
{
	Employee,
	Company,
	InjuryUserChanged,
	User,
	Injury,
	Restriction,
	StatusReport,
	InjuryFile,
	Login
}

export interface Action {
	userId: number;
	user: User;
	
	actionType: ActionType;
	time: number;
	objectId: number;
	objectType: ActionObject;
	objectName: string;
	json: string;
}