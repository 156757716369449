import * as React from 'react';

import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';

import Autocomplete, { Option } from "@common/react/components/Forms/Autocomplete/Autocomplete";

import CommonFilters, { FilterBaseState } from "@app/components/UI/CommonFilter/CommonFilter";
import { User, UserRole } from "@app/objects/User";

import Datepicker from "@common/react/components/Forms/Datepicker/Datepicker";
import { dateFormat, getUserName, getUserNameWithEmail } from "@app/components/Utils/Utils";

type ComponentsProps = OwnProps;

interface OwnProps {
    onApply: <EmployeesFiltersValues>(values) => void
}

export interface ReportsActivityFiltersValues {
    user: User | null;
    role: UserRole | -1;
    date: number[] | null[];
}

export default class ReportsActivityFilters extends CommonFilters<ComponentsProps, FilterBaseState, ReportsActivityFiltersValues> {
    constructor(props: ComponentsProps) {
        super(props);

        const that = this;

        this.state = {
            filters: {
                user: {
                    id: 1,
                    caption: 'User',
                    defaultValue: null,
                    value: null,
                    getValue: function () {
                        return that.getFilterMarkup(this, this.value && getUserName(this.value as User), 'user');
                    }
                },
                role: {
                    id: 2,
                    caption: 'Role',
                    defaultValue: -1,
                    value: -1,
                    check: function () {
                        return +this.value !== this.defaultValue;
                    },
                    getValue: function () {
                        return that.getFilterMarkup(this, UserRole[this.value], 'role');
                    }
                },
                date: {
                    id: 4,
                    caption: 'Date',
                    defaultValue: [null, null],
                    value: [null, null],
                    getValue: function () {
                        if (this.value[0] !== null && this.value[1] !== null) {
                            return that.getFilterMarkup(this, `From ${dateFormat(this.value[0])} - ${dateFormat(this.value[1])}`, 'date')
                        } else if (this.value[0] !== null) {
                            return that.getFilterMarkup(this, `From ${dateFormat(this.value[0])}`, 'date')
                        } else {
                            return that.getFilterMarkup(this, `To ${dateFormat(this.value[1])}`, 'date')
                        }
                    },
                    check: function () {
                        return this.value[0] !== this.defaultValue[0] || this.value[1] !== this.defaultValue[1];
                    }
                }
            },
            visible: false,
        };

        this.resetFilters = this.resetFilters.bind(this);
    }

    getFiltersContent() {
        const { filters } = this.state;

        const getFormContainer = (triggerNode: Element) => document.getElementById('filters-component__form') || document.body;

        return <div className="filters-component__popup clearfix">
            <Formik
                initialValues={
                    {
                        role: filters.role.value,
                        date: filters.date.value,
                        user: filters.user.value
                    }
                }
                onSubmit={this.applyFilters}
                enableReinitialize={true}
                render={(formikBag: FormikProps<ReportsActivityFiltersValues>) => (
                    <Form id="filters-component__form" className="filters-component__form">
                        <div className="row">
                            <Field
                                name="user"
                                children={({ field, form }: FieldProps<ReportsActivityFiltersValues>) => (
                                    <div className={`form-group col-sm-4`}>
                                        <label htmlFor="">User</label>
                                        <Autocomplete type="userList"
                                            renderOption={(item: User) => {
                                                const name = getUserNameWithEmail(item);
                                                const itemProps = { item: item };

                                                return <Option key={item.id + ''} value={item.id + ''} title={name} {...itemProps}>{name}</Option>
                                            }}
                                            onSelect={(value, option) => { formikBag.setFieldValue(field.name, option.props.item); }}
                                            onChange={(value = '') => { value === '' && formikBag.setFieldValue(field.name, null); }}
                                            value={field.value ? getUserName(field.value) : ''}
                                            antdProps={{
                                                getPopupContainer: getFormContainer
                                            }}
                                        />
                                    </div>
                                )}
                            />
                            <Field name="role"
                                children={({ field, form }: FieldProps<ReportsActivityFiltersValues>) => (
                                    <div className={`form-group col-sm-4`}>
                                        <label htmlFor="role">Role</label>
                                        <div className="is-relative">
                                            <select className="form-control input-sm" id="role" {...field}>
                                                <option value={-1}>All</option>
                                                <option value={1}>{UserRole[1]}</option>
                                                <option value={2}>{UserRole[2]}</option>
                                                <option value={3}>{UserRole[3]}</option>
                                            </select>
                                        </div>
                                    </div>
                                )}
                            />
                            <div className="form-group col-sm-4 filters-component__date">
                                <label>Date</label>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-sm-12 col-md-6 DateFrom">
                                            <Datepicker
                                                value={formikBag.values.date[0]}
                                                format="MM/DD/YY"
                                                onChange={date => formikBag.setFieldValue('date.0', date ? new Date(date).setHours(0, 0, 0, 0) : date)}
                                                antdProps={{
                                                    placeholder: "From",
                                                    getCalendarContainer: getFormContainer
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-6 DateTo">
                                            <Datepicker
                                                value={formikBag.values.date[1]}
                                                format="MM/DD/YY"
                                                onChange={date => formikBag.setFieldValue('date.1', date ? new Date(date).setHours(24, 0, 0, -1) : date)}
                                                antdProps={{
                                                    placeholder: "To",
                                                    getCalendarContainer: getFormContainer
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.filtersButtons()}
                    </Form>
                )}
            />
        </div>;
    }
}

